/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable react/react-in-jsx-scope */
import Link from "next/link";
import StaticFooter from "./StaticFooter";
import Image from "next/image";
import clientConfig from "../../client-config";

const Footer = (props) => {
	const { footerMenu } = props;

	return (
        <div id="footer" className="footer">
			<div className="lg:container mx-auto">
				<div className="py-8 px-4 lg:py-22 lg:mr-4 xl:px-0 text-gray text-center">
					{footerMenu?.menuItems?.nodes?.length ?
					<div className="footer-text flex-col lg:flex-row flex lg:text-left justify-between">
						{
							footerMenu?.menuItems?.nodes?.map((seccion) =>
								<div key={seccion.id} className="text-black mr-2 mt-5 lg:mt-0">
									<span className="text-sm font-helvetica-text font-normal antialiased">{seccion.label}</span>
									{
										seccion.label != "Social" ?
											(seccion.childItems.nodes.length != 0 ? (
												<div className="social-links mt-2 align-center">
													{seccion.childItems.nodes.map(single =>
														<div key={single.id} className="mt-3">
															<Link
                                                                href={`${single.path}`}
                                                                prefetch={false}
                                                                className="text-gray-darkest text-sm font-helvetica-text font-normal antialiased">{single.label}</Link>
														</div>
													)}
												</div>
											) : (<></>)
											) : (
												seccion.childItems.nodes.length != 0 ? (
													<div className="social-links mt-2 align-center">
														{seccion.childItems.nodes.map(single =>
															<div key={single.id} className="mt-3">
																<Link
                                                                    href={`${single.path}`}
                                                                    prefetch={false}
                                                                    className="text-gray-darkest text-sm font-helvetica-text font-normal antialiased"
                                                                    rel="noreferrer"
                                                                    target="_blank">{single.label}</Link>
															</div>
														)}
													</div>
												) : (<></>)
											)
									}
								</div>
							)}
					</div>
					: <StaticFooter/>
				}
				</div>
			</div>
			<div className="sic container mx-auto flex justify-center items-center text-gray-darkest text-sm font-helvetica-text font-normal antialiased">Este es un sitio seguro: 
				<a href="https://www.sic.gov.co" aria-label="Industria y Comercio, Superintendencia" className="ml-2">
					<img
						src={clientConfig.sic}
						alt="Industria y Comercio, Superintendencia"
						width={145}
						height={32}						
					/>
				</a>
			</div>
			<div className="text-gray-darkest copyright p-3">
				
				<ul className="flex flex-col lg:flex-row text-center align-center justify-center text-sm font-helvetica-text font-normal antialiased">
					<li className="flex justify-center">
						<h4 className="mt-3 lg:mt-0">Classic Jeans Shops S.A.S. | N.I.T. 900.675.626-1 | Calle 46 No. 52-39 Barranquilla, Colombia | Tel: 605-311-2210 Ext. 101 | Cel: 310-366-2024 | notificaciones@classicjeans.com.co</h4>
					</li>				
				</ul>
			</div>
			<div className="text-gray-darkest copyright p-3">
				<ul className="md:grid md:grid-cols-7 lg:grid-cols-9 3xl:grid-cols-11 flex flex-col lg:flex-row text-center align-center justify-center md:divide-x divide-gray-darkest text-sm font-helvetica-text font-normal antialiased">
					<li className="md:col-start-3 md:col-end-4 xl:col-start-4 xl:col-end-5 3xl:col-start-5 3xl:col-end-6 flex order-last md:order-first justify-center">
						<h4 className="mt-3 lg:mt-0">2021-2024. Classic Jeans</h4>
					</li>
					<li className="mt-3 lg:mt-0"><Link href="/politica-de-privacidad" prefetch={false}>Política de Privacidad</Link></li>
					<li className="mt-3 lg:mt-0"><Link href="/terminos-y-condiciones" prefetch={false}>Términos y Condiciones</Link></li>
				</ul>
			</div>

		</div>
    );
};

export default Footer;