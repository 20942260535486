import gql from "graphql-tag";

const GET_CART = gql`
query GET_CART {
  cart {
    subtotal
    subtotalTax
    shippingTax
    shippingTotal
    total
    totalTax
    discountTax
    discountTotal
    isEmpty
    contents {
      nodes {
        key
        quantity
        subtotal
        subtotalTax
        total
        variation {
          node {
            price
            regularPrice
            salePrice
            sku
            slug
            stockQuantity
            name
            image {
              sourceUrl(size: THUMBNAIL)
              altText
            }
            id
            databaseId
            attributes {
              nodes {
                name
                attributeId
                label
                value
              }
            }
            description
            descripcionBodega
            descripcionCiudad
            ciudadOrigen
            hexColor
            hexColorMayor
            idBodega
            idColor
          }
        }
        product {
          node {
            databaseId
            description
            name
            shortDescription
            sku
            slug
            title
          }
        }
      }
    }
  }
}
`;

export default GET_CART;
