const clientConfig = {
 // productImagePlaceholder: 'https://via.placeholder.com/434', // e.g https://via.placeholder.com/434 - Placeholder image URL for index page
  //singleImagePlaceholder: 'https://via.placeholder.com/200', // e.g https://via.placeholder.com/200 - Placeholder image URL for individual product page
  thumbImagePlaceholder: "https://res.cloudinary.com/dl404fcuc/image/upload/f_auto,q_auto,w_100,h_100/v1/classicjeans-productos/public/no-img-product-690x690",
  flyCartImagePlaceholder: "https://res.cloudinary.com/dl404fcuc/image/upload/f_auto,q_auto,w_120,h_124/v1/classicjeans-productos/public/no-img-product-690x690",
  cartImagePlaceholder: "https://res.cloudinary.com/dl404fcuc/image/upload/f_auto,q_auto,w_150,h_150/v1/classicjeans-productos/public/no-img-product-690x690",
  productImagePlaceholder: "https://res.cloudinary.com/dl404fcuc/image/upload/f_auto,q_auto,w_282,h_300/v1/classicjeans-productos/public/no-img-product-690x690",
  productImagePlaceholder282: "https://res.cloudinary.com/dl404fcuc/image/upload/f_auto,q_auto,w_282,h_282/v1/classicjeans-productos/public/no-img-product-690x690",
  singleImagePlaceholder: "https://res.cloudinary.com/dl404fcuc/image/upload/f_auto,q_auto/v1/classicjeans-productos/public/no-img-product-690x690",
  spinner: "/cart-spinner.gif",
  favicon: "/favicon.ico",
  master: "/img/mastercard-icon.png",
  visa: "/img/visa-icon.png",
  amex: "/img/amex-icon.png",
  cvc:"/img/cvc-icon.png",
  bag:"/img/bag-icon.png",
  ok:"/img/ok-icon.png",
  code:"/img/code-icon.png",
  efectyIcon:"/img/efecty-icon.png",
  time:"/img/time-icon.png",
  cedula:"/img/cedula-icon.png",
  credential:"/img/credential-icon.png",
  phone:"/img/phone-icon.png",
  cancel:"/img/cancel-icon.png",
  logo:"/img/logo-classicjeans.svg",
  sic:"/img/logo-sic-footer.png",
  home:"/img/logo-home.png",
  shop: "/img/logo-shop.png",
  addi: "/img/logo-addi.png",
  efecty:"/img/logo-efecty.png",
  pse:"/img/logo-pse.png",
  bancolombia:"/img/logo-bancolombia.png",
  payu:"/img/logo-payu-square-lime.png",
  payuLarge:"/img/logo-payu-original.png",
  efectivo: "/img/logo-efectivo.png",
  cards: "/img/logo-cards.png",
  nequi: "/img/logo-nequi.png",
  contraEntrega: "/img/logo-cash-on-delivery.png",
  callCenter:"573103662024",
};

export default clientConfig;
